import { addEventListener } from "../utils/EventUtils";
import Logger from "../utils/Logger";
import { closest } from "../utils/JsUtils";
import { debounce, throttle } from "lodash";
import { TweenMax, TimelineLite, Power1, Linear, CSSPlugin } from "gsap/all";

const MathUtils = {
  lerp: (a, b, n) => {
    return (1 - n) * a + n * b;
  },
};

export default class Cursor {
  constructor() {
    Logger.log("Cursor->constructor()");

    // Properties
    this.showed = false;
    this.isStuck = false;
    this.isPositionned = false;
    this.currentStuckItem = null;
    this.stuckX = 0;
    this.stuckY = 0;
    this.lastStuckX = 0;
    this.lastStuckY = 0;
    this.mouseX = 0;
    this.mouseY = 0;
    this.lastX = 0;
    this.lastY = 0;
    this.dx = 0;
    this.dy = 0;
    this.tx = 0;
    this.ty = 0;
    this.key = 0;
    this.easingStuckDefault = 0.2;
    this.easingStuck = 0.5;
    window.addEventListener("DOMContentLoaded", (event) => {
      this.initDomElements();
      this.addEventListeners();
      this.initTimelines();
      this.run();
    });
  }

  initDomElements() {
    document.body.style.cursor = "none";

    this.$parent = document.querySelector(".c-cursor");
    this.$pointer = this.$parent.querySelector(".c-cursor__pointer");
    this.$cursor = this.$parent.querySelector(".c-cursor__container");

    this.$colorTriggers = document.querySelectorAll("[data-cursor-color]");
    this.$stateTriggers = document.querySelectorAll("[data-cursor-state]");
  }

  initTimelines() {
    this.stuckTl = new TimelineLite({
      paused: true,
      onComplete: () => {
        TweenMax.set([this.$pointer, this.$cursor], { autoAlpha: 0 });
      },
    });
    this.stuckTl.set([this.$pointer, this.$cursor], { autoAlpha: 1 });
    this.stuckTl.fromTo(
      [this.$pointer, this.$cursor],
      0.25,
      {
        xPercent: "-50%",
        yPercent: "-50%",
        scale: 1,
      },
      { scale: 0, xPercent: "-50%", yPercent: "-50%", ease: Linear.easeNone }
    );
  }

  resetStuckTimeline() {
    if (this.echoStuckTl) {
      // Clear inline properties to avoid "pause" on echos
      TweenMax.set([this.echo_inner, this.echo_outer], { clearProps: "all" });
      this.echoStuckTl.kill();
    }

    if (this.currentStuckItem) {
      this.is_button = this.currentStuckItem.classList.contains("c-button")
        ? true
        : false;
      this.checkShouldRepeat = this.checkShouldRepeat.bind(this);
      this.echoStuckTl = new TimelineLite({
        paused: true,
        delay: 0,
        onRepeat: this.checkShouldRepeat,
      });
      this.echo_outer =
        this.currentStuckItem.querySelector(".c-button__circle--outer__echo") ||
        this.currentStuckItem.querySelector(".c-site-menu__circle_outer__echo");
      this.echo_inner =
        this.currentStuckItem.querySelector(".c-button__circle--inner__echo") ||
        this.currentStuckItem.querySelector(".c-site-menu__circle_inner__echo");
      var echoScale = this.is_button ? "310%" : "750%";
      var echoDuration = this.is_button ? 1.3 : 1.9;
      this.echoStuckTl
        .set([this.echo_inner, this.echo_outer], { autoAlpha: 1, delay: 0.1 })
        .fromTo(
          this.echo_inner,
          echoDuration,
          { width: "110%", height: "110%" },
          {
            autoAlpha: 0,
            delay: 0.1,
            width: echoScale,
            height: echoScale,
            ease: Linear.easeIn,
          },
          "start"
        )
        .fromTo(
          this.echo_outer,
          echoDuration,
          { width: "110%", height: "110%" },
          {
            autoAlpha: 0,
            width: echoScale,
            height: echoScale,
            ease: Linear.easeIn,
          },
          "start"
        );

      // Adjust properties for button animation
      if (this.is_button) {
        this.echoStuckTl.repeat(-1);
        this.easingStuck = this.easingStuckDefault;
      }
    }
  }

  checkShouldRepeat() {
    if (this.isStuck != true) {
      this.echoStuckTl.pause();
    }
    if (this.isStuck == true && this.echoStuckTl.repeat() == -1) {
      setTimeout(() => {
        this.echoStuckTl.play();
      }, 250);
    }
  }

  addEventListeners() {
    addEventListener(
      document,
      "mousemove",
      this._updateMousePosition.bind(this)
    );

    // Bind hover change state
    if (this.$stateTriggers) {
      for (let i = 0, j = this.$stateTriggers.length; i < j; i++) {
        addEventListener(
          this.$stateTriggers[i],
          "mouseenter",
          this.handleMouseEnter.bind(this)
        );
        addEventListener(
          this.$stateTriggers[i],
          "mouseleave",
          throttle(this.handleMouseLeave.bind(this), 250)
        );
      }
    }
  }

  handleMouseLeave() {
    this.$cursor.style.display = "block";
    this.isPositionned = false;
    this.isStuck = false;

    if (this.currentStuckItem) {
      this.currentStuckItem.classList.remove("focused");
    }
    TweenMax.set([this.$pointer, this.$cursor], { autoAlpha: 1 });
    this.stuckTl.reverse();
  }

  handleMouseEnter(e) {
    let navItem = e.currentTarget;
    if (
      !navItem.classList.contains("c-button") ||
      !navItem.classList.contains("c-site-menu__burger")
    ) {
      navItem =
        navItem.querySelector(".c-button") ||
        navItem.querySelector(".c-site-menu__burger");
      if (!navItem) {
        // Try to find button from parent
        navItem =
          e.currentTarget.parentNode.querySelector(".c-button") ||
          e.currentTarget.parentNode.querySelector(".c-site-menu__burger");
      }
    }

    if (this.currentStuckItem != navItem) {
      this.currentStuckItem = navItem;
      this.resetStuckTimeline();
    } else {
      if (!this.echoStuckTl.isActive()) this.echoStuckTl.seek(0);
    }
    this.isStuck = true;
  }

  _updateMousePosition(e) {
    this.mouseX = e.clientX || e.pageX;
    this.mouseY = e.clientY || e.pageY;

    // Check if new cursor color was encounter
    var color = e.target.getAttribute("data-cursor-color");
    if (color) {
      this._color = color;
    } else {
      // Find closest :: remove to fix first section home wrong trigger..
      var parent = closest(e.target, "[data-cursor-color]");
      if (parent) {
        var parentColor = parent.getAttribute("data-cursor-color");
        if (parentColor) {
          this._color = parentColor;
        }
      }
    }

    // Show if it's first run
    if (this.showed == false) {
      this.showed = true;
      this.$parent.style.display = "block";
    }
  }

  run() {
    if (!this.lastX || !this.lastY) {
      this.lastX = this.mouseX;
      this.lastY = this.mouseY;
    } else {
      this.dx = (this.mouseX - this.lastX) * 0.1;
      this.dy = (this.mouseY - this.lastY) * 0.1;
      if (Math.abs(this.dx) + Math.abs(this.dy) < 0.1) {
        this.lastX = this.mouseX;
        this.lastY = this.mouseY;
      } else {
        this.lastX += this.dx;
        this.lastY += this.dy;
      }
    }

    // Move pointer (dot) before delaying value
    // If no state / stuck, move delayed circle
    if (!this.isStuck) {
      this.$parent.classList.remove("is-stuck");

      this.lastStuckX = this.lastX;
      this.lastStuckY = this.lastY;

      // Move delayed circle
      this.$cursor.style.left = this.lastX + "px";
      this.$cursor.style.top = this.lastY + "px";

      this.$pointer.style.left = this.mouseX + "px";
      this.$pointer.style.top = this.mouseY + "px";
    } else {
      // Get current references
      if (this.currentStuckItem) {
        let navItemBox = this.currentStuckItem.getBoundingClientRect();
        this.stuckX = Math.round(navItemBox.left + navItemBox.width / 2);
        this.stuckY = Math.round(navItemBox.top + navItemBox.height / 2);
        this.currentStuckItem.classList.add("focused");
      }

      // Check if cursor is fixed to his CTA
      if (this.isPositionned == false) {
        // delayed circle
        if (
          Math.abs(this.lastStuckX - this.stuckX) > 1 ||
          Math.abs(this.lastStuckY - this.stuckY) > 1
        ) {
          if (!this.echoStuckTl.isActive()) this.echoStuckTl.play();

          // Animate if not positionned
          this.lastStuckX = MathUtils.lerp(
            this.lastStuckX,
            this.stuckX,
            this.easingStuck
          );
          this.lastStuckY = MathUtils.lerp(
            this.lastStuckY,
            this.stuckY,
            this.easingStuck
          );
        } else {
          // Prevent scroll delay on stucked element
          this.isPositionned = true;

          this.lastStuckX = this.stuckX;
          this.lastStuckY = this.stuckY;
        }
      } else {
        this.lastStuckX = this.stuckX;
        this.lastStuckY = this.stuckY;
      }

      this.$pointer.style.left = this.lastStuckX + "px";
      this.$pointer.style.top = this.lastStuckY + "px";

      this.$cursor.style.left = this.lastStuckX + "px";
      this.$cursor.style.top = this.lastStuckY + "px";

      this.$parent.classList.add("is-stuck");
      this.stuckTl.play();
    }

    // Update color
    if (this._color == "white") this.$parent.classList.add("white");
    if (this._color == "black") this.$parent.classList.remove("white");

    requestAnimationFrame(this.run.bind(this));
  }
}
