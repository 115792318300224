import { TimelineLite, Power1 } from "gsap/all";
import { addEventListener } from "../utils/EventUtils";

export default class NewsLetter {
  constructor() {
    this.timeline = new TimelineLite();
    this.sended = false;

    this.toggleButtons = document.querySelectorAll(
      ".c-news-letter-open-button,.c-news-letter-close-button,.c-news-letter-form__shadow"
    );
    this.newsLetter = document.querySelector(".c-news-letter-form");
    this.formWrapper = this.newsLetter.querySelector(
      ".c-news-letter-form__wrapper"
    );
    this.form = document.querySelector("#mailjetSubscriptionForm");
    this.shadow = document.querySelector(".c-news-letter-form__shadow");
    this.spinner = this.newsLetter.querySelector(".lds-ring");
    this.message = this.newsLetter.querySelector(
      ".mailjet_widget_form_message"
    );
    this.openbuttonLabel = document.querySelector(
      ".c-news-letter-open-button .c-button__label"
    );
    this.openbuttonLabel.innerText =
      document.querySelector(".widgettitle").innerText;
    this.initEventListeners();
  }
  initEventListeners() {
    addEventListener(
      this.toggleButtons,
      "click",
      this.openNewsLetter.bind(this)
    );
    addEventListener(this.form, "submit", this.submitForm.bind(this));
  }
  openNewsLetter() {
    this.shadow.classList.toggle("shadow-open");
    this.newsLetter.classList.toggle("c-news-letter-open");
  }
  async handleForm() {
    let data = {
      subscription_email: this.form.subscription_email.value,
      subscription_locale: this.form.subscription_locale.value,
      action: "send_mailjet_subscription_form",
      widget_id: "wp_mailjet_subscribe_widget-2",
    };
    try {
      const result = await fetch(ajaxurl, {
        method: "post",
        mode: "cors",
        headers: { "content-type": "application/x-www-form-urlencoded" },
        body: new URLSearchParams(data),
      });
      let message = await result.text();
      if (!message.includes("invalid")) {
        this.message.innerText = message;
        this.displayMessage();
        this.sended = true;
      } else {
        this.message.innerText = message;
        this.displayMessage();
        this.showForm();
      }
    } catch (error) {
      this.showForm();
    }
  }
  hideForm() {
    this.formWrapper.style.display = "none";
    this.spinner.style.display = "block";
  }
  showForm() {
    this.formWrapper.style.display = "flex";
    this.spinner.style.display = "none";
  }
  displayMessage() {
    this.spinner.style.display = "none";
    this.timeline.fromTo(
      this.message,
      0.8,
      { opacity: 0, x: -10, ease: Power1.easeInOut },
      { opacity: 1, x: 0 }
    );
  }
  submitForm(e) {
    e.preventDefault();
    if (this.form.checkValidity()) {
      this.hideForm();
      this.handleForm();
    }
  }
}
