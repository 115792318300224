import EventEmitter from "eventemitter3";
import AppController from "../controllers/AppController";

export default class AbstractComponent extends EventEmitter {
  constructor(el) {
    super();
    this.$el = el;

    // Checking abstract class instantiation
    if (this.constructor === AbstractComponent) {
      throw new TypeError(
        'Abstract class "AbstractComponent" cannot be instantiated directly.'
      );
    }

    // Checking abstract methods
    if (this.destroy === undefined) {
      throw new TypeError(
        'Classes extending the "AbstractComponent" abstract class need to implement all required methods.'
      );
    }

    // Default fields
    this.app = AppController.getInstance();
  }

  // /** To implement */
  // abstract destroy() {}
}
