import AbstractComponent from "./AbstractComponent";
import Logger from '../utils/Logger';
import Flickity from 'flickity';
import { addEventListener, removeEventListener, preventDefault } from "../utils/EventUtils";


export default class ProjectsMobileSlider extends AbstractComponent {
    constructor( ...args ) {
        Logger.log( 'ProjectsMobileSlider->constructor()' );
        super( ...args );

        this.classname = 'c-projects-mobile-slider';
        this.initDomElements();
        this.initSlider();
        this.addEventListeners();
    }


    initDomElements() {
        this.$slider =  this.$el.querySelector( '.js-' + this.classname + '__slider' );
        this.$sliderPrevButton = this.$slider.querySelector( '.js-' + this.classname + '__slider__button--prev' );
        this.$sliderNextButton = this.$slider.querySelector( '.js-' + this.classname + '__slider__button--next' );
        this.$title = this.$el.querySelector( '.js-' + this.classname + '__title' );
        this.$button = this.$el.querySelector( '.js-' + this.classname + '__button' );
    }


    initSlider() {
        this.slider = new Flickity( this.$slider, {
            cellSelector: '.js-c-projects-mobile-slider__slide',
            wrapAround: true,
            pageDots: false,
            prevNextButtons: false
        } );
    }


    addEventListeners() {
        this.boundSliderPrevButtonClickListener = this.sliderPrevButtonClicListener.bind( this );
        addEventListener( this.$sliderPrevButton, 'click', this.boundSliderPrevButtonClickListener );

        this.boundSliderNextButtonClickListener = this.sliderNextButtonClicListener.bind( this );
        addEventListener( this.$sliderNextButton, 'click', this.boundSliderNextButtonClickListener );
    
        this.boundSliderChangeListener = this.sliderChangeListener.bind( this );
        this.slider.on( 'change', this.boundSliderChangeListener );
    }


    sliderPrevButtonClicListener( e ) {
        preventDefault( e );
        this.slider.previous();
    }


    sliderNextButtonClicListener( e ) {
        preventDefault( e );
        this.slider.next();
    }


    sliderChangeListener( index ) {
        const cellElements = this.slider.getCellElements();
        if ( cellElements.length > index ) {
            const selectedSlide = cellElements[index];
            if ( selectedSlide ) {
                const title = selectedSlide.getAttribute( 'data-title' );
                const titleEmphasis = selectedSlide.getAttribute( 'data-title-emphasis' );
                const url = selectedSlide.getAttribute( 'data-url' );
                this.updateSelectedSlideData( title, titleEmphasis, url );
            }
        }
    }


    updateSelectedSlideData( title, titleEmphasis, url ) {
        let titleHTML = '';
        if ( title ) {
            titleHTML += title;
        }
        if ( titleEmphasis ) {
            titleHTML += '<span class="' + this.classname + '__title__emphasis">';
            titleHTML += titleEmphasis;
            titleHTML += '</span>';
        }
        titleHTML += '<div class="u-crosshair"></div>';
        this.$title.innerHTML = titleHTML;
        this.$button.setAttribute( 'href', url );
    }


    destroy() {
        Logger.log( 'ProjectsMobileSlider->destroy()' );

        this.removeEventListeners();
    }


    removeEventListeners() {
        removeEventListener( this.$sliderPrevButton, 'click', this.boundSliderPrevButtonClickListener );
        removeEventListener( this.$sliderNextButton, 'click', this.boundSliderNextButtonClickListener );
        this.slider.off( 'change', this.boundSliderChangeListener );
    }
}
