export default class Realizations {
  constructor() {
    this.realizations = document.querySelectorAll(
      ".c-list-realizations__container__item"
    );
setTimeout(() => {
  this.realizations.forEach(realization =>{
    realization.querySelector('.hover_move').style.setProperty('--push-down', realization.querySelector('.terms-list').scrollHeight + 25 + "px");
    })
}, 300);
  }
}
