import AbstractPageController from './AbstractPageController';
import { css } from '../utils/CssUtils';
import { addEventListener, removeEventListener } from '../utils/EventUtils';
import { createElementFromHTML } from '../utils/JsUtils';
import Logger from '../utils/Logger';
import {debounce} from "lodash";
const MathUtils = {
    lerp: (a, b, n) => {
        return (1 - n) * a + n * b
    },
    norm: (value, min, max) => {
        return (value - min) / (max - min)
    }
}
let ScrollConfig = {
    height: window.innerHeight,
    width: window.innerWidth
}

export default class SmoothScrollController extends AbstractPageController {
    constructor( callback = null ) {
        super( callback );
        Logger.log( 'SmoothScrollController->constructor()' );

        // Default fields
        this.smoothScroll = {
            ease: 1,
            current: 0,
            last: 0,
            running: false,
        };

        this.initDomElements();
        this.addEventListeners();
        this.init();
        this.ready();
    }

    initDomElements() {
        this.$el = document.querySelector('body .js-smooth-scroll');
        this.$content = this.$el.querySelector('main');
        this.rAF = null;
    }

    addEventListeners() {
        ['scroll', 'run', 'resize']
            .forEach((fn) => this[fn] = this[fn].bind(this));

        addEventListener( window, 'resize', debounce( this.resize.bind( this ), 200 ) );

        // FIX WRONG HEIGHT ONLOAD..
        setTimeout( () => {
            this.resize();
        }, 1000 );
    }


    init() {
        this.setHeight();
        this.enable();
    }

    switch( newPage ) {

        // Destroy process
        if( this.smoothScroll.running == true )
            this.off();

        // Init new scroll content
        this.$content = newPage;

        // Init again
        this.init();
    }

    bind() {
        ['scroll', 'run', 'resize']
            .forEach((fn) => this[fn] = this[fn].bind(this));
    }

    resize( width, height ) {
        this.setHeight();
        this.scroll();
    }

    scroll() {
        this.smoothScroll.current = window.scrollY;
    }

    getPosition() {
        return this.smoothScroll.current;
    }

    run() {
        // Optimize perf, stop when translate is finished
        if( this.smoothScroll.last.toFixed(0) > this.smoothScroll.current.toFixed(0) || this.smoothScroll.last.toFixed(0) < this.smoothScroll.current.toFixed(0) ) {
            this.smoothScroll.last = MathUtils.lerp(this.smoothScroll.last, this.smoothScroll.current, this.smoothScroll.ease);
            if (this.smoothScroll.last < 1) {
                this.smoothScroll.last = 0;
            }

            this.$content.style.transform = `translate3d(0, -${this.smoothScroll.last.toFixed(1)}px, 0)`;

            this.emit( 'smooth_update', { last: this.smoothScroll.last.toFixed(1) } );
        }
        this.requestAnimationFrame();
    }

    setStyles() {
        Object.assign( this.$el.style, {
            position: 'fixed',
            top: 0,
            left: 0,
            height: '100%',
            width: '100%',
            overflow: 'hidden'
        });
    }

    setHeight() {
        // If offsetTop add it
        document.body.style.height = this.$content.offsetHeight + "px";
    }

    enable() {
        this.setStyles();
        this.setHeight();
        this.addEvents();

        this.smoothScroll.running = true;
        this.requestAnimationFrame();
    }

    off() {
        this.cancelAnimationFrame();

        this.removeEvents();
        this.smoothScroll.running = false;
    }

    requestAnimationFrame() {
        this.rAF = requestAnimationFrame(this.run);
    }

    cancelAnimationFrame() {
        cancelAnimationFrame(this.rAF);
    }

    destroy() {
        document.body.style.height = '';

        this.smoothScroll = null;

        this.removeEvents();
        this.cancelAnimationFrame();
    }

    resize() {
        ScrollConfig = {
            height: window.innerHeight,
            width: window.innerWidth
        }
        this.setHeight();
    }

    addEvents() {
        window.addEventListener('scroll', this.scroll, { passive: true });
    }

    removeEvents() {
        window.removeEventListener('scroll', this.scroll, { passive: true });
    }

    resetTop() {
        // window.scrollTo(0,0);
        this.smoothScroll.current = 0;
        this.$content.style.transform = `translate3d(0, -${this.smoothScroll.current}px, 0)`;
    }


    destroy() {
        Logger.log( 'SmoothScrollController->destroy()' );
    }
}


// export default class SmoothScrollController extends AbstractPageController {
//     constructor( callback = null ) {
//         super( callback );
//         Logger.log( 'SmoothScrollController->constructor()' );
//
//         // Default fields
//         this.smoothScroll = {
//             current: 0,
//             destination: 0,
//             easing: 0.1,
//             isRunning: false
//         };
//
//         this.initDomElements();
//         this.addEventListeners();
//         this.ready();
//     }
//
//
//     initDomElements() {
//         this.$body = document.getElementsByTagName( 'body' )[0];
//         this.$smoothScroll = document.querySelector( '.js-smooth-scroll' );
//         this.$smoothScrollHeight = createElementFromHTML( '<div></div>' );
//         css( this.$smoothScrollHeight, {
//             position: 'relative',
//             top: 0,
//             left: 0,
//             width: '1px',
//             visibility: 'hidden'
//         } );
//         this.$body.appendChild( this.$smoothScrollHeight );
//         setInterval( () => {
//             css( this.$smoothScrollHeight, { height: this.$smoothScroll.getBoundingClientRect().height + 'px' } );
//         }, 16 );
//     }
//
//
//     addEventListeners() {
//         this.boundScrollEventListener = this.scrollEventListener.bind( this );
//         addEventListener( window, 'scroll', this.boundScrollEventListener );
//         this.boundScrollEventListener();
//     }
//
//
//     scrollEventListener() {
//         this.smoothScroll.destination = window.scrollY || window.pageYOffset;
//
//         if ( !this.smoothScroll.isRunning ) {
//             requestAnimationFrame( this.smoothScrollHandler.bind( this ) );
//         }
//     }
//
//
//     smoothScrollHandler () {
//         this.smoothScroll.isRunning = true;
//
//         if ( this.smoothScroll.current === this.smoothScroll.destination ) {
//             this.smoothScroll.isRunning = false;
//             return;
//         }
//
//         this.smoothScroll.current += ( this.smoothScroll.destination - this.smoothScroll.current ) * this.smoothScroll.easing;
//
//         if ( Math.abs( Math.abs( this.smoothScroll.destination ) - Math.abs( this.smoothScroll.current ) ) < 0.1 ) {
//             this.smoothScroll.current = this.smoothScroll.destination;
//         }
//         // console.log(this.smoothScroll.current, this.smoothScroll.destination);
//
//         css( this.$smoothScroll, { transform: 'translate3d( 0, ' + ( -this.smoothScroll.current ) + 'px, 0 )' } );
//
//         this.emit( 'smooth_update' );
//
//         requestAnimationFrame( this.smoothScrollHandler.bind( this ) );
//     }
//
//     getLastPosition() {
//         return this.smoothScroll.current;
//     }
//
//     destroy() {
//         Logger.log( 'SmoothScrollController->destroy()' );
//     }
// }