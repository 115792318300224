import AbstractPageController from "./AbstractPageController";
import Logger from "../utils/Logger";
import { addEventListener } from "../utils/EventUtils";
import { debounce } from "lodash";
import { css } from "../utils/CssUtils";
import { getTimeline } from "../utils/AnimationUtils";
import gsap from "gsap";
import ScrollTrigger from "gsap/dist/ScrollTrigger";

export default class VisionController extends AbstractPageController {
  constructor(...args) {
    super(...args);
    Logger.log("VisionController->constructor()");

    this.visionHighlightsOffsets = {
      top: {
        mobile: 30,
        desktop: 45,
      },
      bottom: {
        mobile: 0,
        desktop: 45,
      },
    };

    gsap.registerPlugin(ScrollTrigger);

    // this.initDomElements();
    this.addEventListeners();
    // this.updateVisionHighlightsBlackBackground();
    // setInterval( this.updateVisionHighlightsBlackBackground.bind( this ), 500 );

    this.revealImages();

    // Animations
    this.timelines = [];
    if (this.app.isDesktop) {
      this.createScene();
    }

    this.ready();
  }

  revealImages() {
    this.stakeImgWrapper = document.querySelector(".agency-stake .img");
    this.rseImgWrapper = document.querySelector(".agency-rse .img");

    const imgs = [
      document.querySelector(".agency-curious .mask img"),
      // document.querySelector(".agency-stake .img"),
    ];

    // imgs.forEach((img) => {
    const agencyImg = document.querySelector(".agency-curious .mask img");
    gsap.fromTo(
      agencyImg,
      {
        // opacity: 0,
        scale: 1.2,
      },
      {
        // opacity: 1,
        scale: 1,

        scrollTrigger: {
          trigger: agencyImg,
          start: "top 75%",
          // markers: {},
        },
      }
    );

    if (window.innerWidth > 1024) {
      
      this.tween = gsap.fromTo(
        this.rseImgWrapper,
        {
          y: 0,
        },
        {
          y: "55%",

          scrollTrigger: {
            trigger:this.rseImgWrapper,
            start: "top 50%",
            scrub: true,
          },
        }
      );
      this.tween = gsap.fromTo(
        this.stakeImgWrapper,
        {
          // opacity: 0,
          y: 0,
          // scale: 1.2,
        },
        {
          // opacity: 1,
          y: "75%",
          // scale: 1,

          scrollTrigger: {
            trigger: this.stakeImgWrapper,
            start: "top 50%",
            // markers: {},
            scrub: true,
          },
        }
      );
    }
    // });
  }

  initDomElements() {
    this.$visionHighlights = document.querySelector(".js-vision-highlights");
    console.log(
      this.$visionHighlights,
      this.$visionHighlightsBlackBackground,
      this.$visionHighlightsBlackBackgroundStart,
      this.$visionHighlightsBlackBackgroundEnd
    );
    this.$visionHighlightsBlackBackground =
      this.$visionHighlights.querySelector(
        ".js-vision-highlights__black-background"
      );
    this.$visionHighlightsBlackBackgroundStart =
      this.$visionHighlights.querySelector(
        ".js-vision-highlights__black-background-start"
      );
    this.$visionHighlightsBlackBackgroundEnd =
      this.$visionHighlights.querySelector(
        ".js-vision-highlights__black-background-end"
      );
  }

  addEventListeners() {
    this.debouncedResizeListener = debounce(
      this.resizeEventListener.bind(this),
      16
    );
    addEventListener(window, "resize", this.debouncedResizeListener);

    // window.addEventListener("resize", this.resize);
  }

  // resize() {
  //   console.log("resize");
  // }

  resizeEventListener() {
    if (this.app.isBreakpointUp("lg") ? "desktop" : "mobile" === "desktop") {
      if (!this.tween) {
        this.tween = gsap.fromTo(
          this.stakeImgWrapper,
          {
            // opacity: 0,
            y: 0,
            // scale: 1.2,
          },
          {
            // opacity: 1,
            y: "75%",
            // scale: 1,

            scrollTrigger: {
              trigger: this.stakeImgWrapper,
              start: "top 50%",
              // markers: {},
              scrub: true,
            },
          }
        );
      }
    } else {
      if (this.tween) this.tween.kill();
      this.stakeImgWrapper.style.transform = "";
    }
    // this.updateVisionHighlightsBlackBackground();
  }

  updateVisionHighlightsBlackBackground() {
    const props = {};
    const topOffset =
      this.visionHighlightsOffsets.top[
        this.app.isBreakpointUp("lg") ? "desktop" : "mobile"
      ];
    const bottomOffset =
      this.visionHighlightsOffsets.bottom[
        this.app.isBreakpointUp("lg") ? "desktop" : "mobile"
      ];

    // Top
    props.top = 0;
    props.top += this.$visionHighlightsBlackBackgroundStart.offsetTop;
    props.top += topOffset;
    props.top = Math.round(props.top);

    // Height
    props.height = 0;
    props.height += this.$visionHighlightsBlackBackgroundEnd.offsetTop;
    props.height +=
      this.$visionHighlightsBlackBackgroundEnd.getBoundingClientRect().height;
    props.height -= props.top;
    props.height -= bottomOffset;
    props.height = Math.round(props.height);

    // Pixels units
    props.top += "px";
    props.height += "px";

    css(this.$visionHighlightsBlackBackground, props);
  }

  createScene() {
    this.$animatedItems = document.querySelectorAll("[data-animation]");
    for (let i = 0, j = this.$animatedItems.length; i < j; i++) {
      let el = this.$animatedItems[i];
      let animationName = el.getAttribute("data-animation");
      let tween = getTimeline(animationName, el);
      this.timelines.push({
        tween: tween,
        el: el,
      });
    }
  }

  /**
   * Update call to render animations
   */
  update() {
    if (this.timelines.length > 0) {
      for (let i = 0, j = this.timelines.length; i < j; i++) {
        const item = this.timelines[i].el;
        item.rect = item.getBoundingClientRect();

        // Special for header
        if (item.getAttribute("data-animation") == "header") {
          var percent = 1 - window.scrollY / window.innerHeight;
        } else {
          var percent =
            (item.rect.top + item.rect.height) /
            (window.innerHeight + item.rect.height);
        }

        let progress = 1 - percent;
        if (progress < 0) continue;
        else if (progress > 1) continue;

        // Update tween
        let tween = this.timelines[i].tween;
        tween.progress(progress);
      }
    }
  }

  destroy() {
    Logger.log("HomeController->destroy()");

    this.removeEventListeners();
  }

  removeEventListeners() {
    removeEventListener(window, "resize", this.debouncedResizeListener);
  }
}
