import {addClass, css, removeClass} from '../utils/CssUtils';
import {addEventListener} from '../utils/EventUtils';
import {getPageHeight, getPageWidth} from '../utils/JsUtils';
import Logger from '../utils/Logger';
import anime from 'animejs';
import {TweenMax, TimelineLite, Sine, Linear, Power1, Slow, Circ, CSSPlugin} from "gsap/all";
import {disableBodyScroll, enableBodyScroll} from 'body-scroll-lock';


export default class SiteMenu {
    constructor() {
        Logger.log('SiteMenu->constructor()');

        this.isOpen = false;
        this.isRunning = false;
        this.mouseOut = true;
        this.locked = false;
        this.currentClass = 'white';

        this.initDomElements();
        this.addEventListeners();
        this.initTimelines();
        this.initFixedActions();
    }


    initDomElements() {
        this.$menu = document.querySelector('.js-c-site-menu');
        this.$burger = this.$menu.querySelector('.c-site-menu__burger');
        this.$burgerBar1 = this.$burger.querySelector('.c-site-menu__bar--1');
        this.$burgerBar2 = this.$burger.querySelector('.c-site-menu__bar--2');
        this.$burgerBar3 = this.$burger.querySelector('.c-site-menu__bar--3');
        this.$burgerBar4 = this.$burger.querySelector('.c-site-menu__bar--4');

        this.$content = this.$menu.querySelector('.c-site-menu__content');

        // Animations
        this.$backgroundCircle = this.$menu.querySelector('.c-site-menu__content__yellow-circle');
        this.$line = this.$menu.querySelector('.c-site-menu__content__line');
        this.$rectTop = this.$menu.querySelector('.c-site-menu__content__rect--top-left');
        this.$rectBottom = this.$menu.querySelector('.c-site-menu__content__rect--bottom-right');
        this.$textualContent = this.$menu.querySelectorAll('.js-c-site-menu__textual-content');
        this.$circleInner = this.$burger.querySelector('.c-site-menu__circle_inner');
        this._circleInnerBorderWidth = this.$circleInner.style.borderWidth;
        this.$circleOuter = this.$burger.querySelector('.c-site-menu__circle_outer');
        this._circleOuterBorderWidth = this.$circleOuter.style.borderWidth;
        this.$echoCircleInner = this.$burger.querySelector('.c-site-menu__circle_inner__echo');
        this.$echoCircleOuter = this.$burger.querySelector('.c-site-menu__circle_outer__echo');


        this.$triggers = document.querySelectorAll('[data-menu-class]');
    }


    addEventListeners() {
        addEventListener(this.$burger, 'click', this.toggleMenu.bind(this));
        addEventListener(this.$burger, 'mouseenter', this.onBurgerMouseEnter.bind(this));
        addEventListener(this.$burger, 'mouseleave', this.onBurgerMouseLeave.bind(this));
    }


    initTimelines() {

        // Opening menu timeline
        this.onMenuReverseComplete = this.onMenuReverseComplete.bind(this);
        this.openTimeline = new TimelineLite({paused: true, onReverseComplete: this.onMenuReverseComplete});
        this.openTimeline
            .fromTo(this.$backgroundCircle, 0.8, {scale: 0}, {scale: 50, ease: Power1.easeInOut}, 'start')
            .fromTo(this.$line, 0.6, {height: '0%'}, {height: '100%', ease: Sine.easeOut}, 'start+=0.4')
            .fromTo(this.$rectTop, 1, {width: '0%'}, {width: '100%', ease: Sine.easeOut}, 'start+=0.85')
            .fromTo(this.$rectBottom, 1, {width: '0%'}, {width: '100%', ease: Sine.easeOut}, 'start+=0.85')
            .staggerFromTo(this.$textualContent, 0.35, {autoAlpha: 0, y: 30}, {
                autoAlpha: 1,
                y: 0,
                ease: Sine.easeOut
            }, 0.15, 'start+=0.9');


        // Echo circle timeline
        this.burgerEchoTimeline = new TimelineLite({paused: true});
        this.burgerEchoTimeline
            .set([this.$echoCircleInner, this.$echoCircleOuter], {autoAlpha: 1})
            .fromTo(this.$echoCircleInner, 2.5, {width: '100%', height: '100%'}, {
                autoAlpha: 0,
                delay: 0.15,
                width: '750%',
                height: '750%',
                ease: Linear.easeOut
            }, 'start')
            .fromTo(this.$echoCircleOuter, 2.5, {width: '100%', height: '100%'}, {
                autoAlpha: 0, width: '750%', height: '750%', ease: Linear.easeOut,
                onComplete: () => {
                    this.isRunning = false;
                    this.retractBurger();
                }
            }, 'start');


    }

    initFixedActions() {
        // Fixed actions
        this.$fixedActions = document.body.querySelector('.js-fixed-actions');
        this.$fixedActionsTriggerIn = document.body.querySelector('.c-header') || document.body.querySelector('.js-fixed-actions__trigger-in');
        this.$fixedActionsTriggerOut = document.body.querySelector('.c-project-flexible-content') || document.body.querySelector('.js-fixed-actions__trigger-out');
        //this.$fixedActionsTriggerOutOffset = document.body.querySelector('.c-project-introduction') || document.body.querySelector('.js-fixed-actions__trigger-out-offset');

        //console.log(this.$fixedActionsTriggerIn);
        //console.log(this.$fixedActionsTriggerOut);
        //console.log(this.$fixedActionsTriggerOutOffset);
        // Progress circle
        this.$progressCircle = document.body.querySelector('.c-site-menu__circle_inner__arc #bar');
    }

    onMenuReverseComplete() {
        removeClass(this.$content, 'is-shown');
        css(this.$content, {opacity: null});
        removeClass(this.$burger, 'is-open');
    }


    onBurgerMouseEnter() {
        // Force animation only if user was out again
        // var forced = this.mouseOut == true ? true : false;
        // this.runEcho( forced );

        this.mouseOut = false;
    }

    onBurgerMouseLeave() {
        this.mouseOut = true;

        this.retractBurger()
    }

    retractBurger() {
        if (this.isOpen == false && this.isRunning == false && this.mouseOut == true) {
            if (window.scrollY > 100)
                this.$burger.classList.add('retracted');
            else
                this.$burger.classList.remove('retracted');
        } else {
            this.$burger.classList.remove('retracted');
        }
    }


    runEcho(forced = false) {

        // if( forced == true ) {
        //     this.isRunning = true;
        //     this.burgerEchoTimeline.play(0);
        // } else {
        if (this.isRunning == false) {
            // Lock process until end of echo
            this.isRunning = true;

            this.$burger.classList.remove('retracted');

            // Play echo
            this.burgerEchoTimeline.play(0);
        }
        // }
    }


    toggleMenu() {
        this.runEcho(true);
        if (this.isOpen) {
            this.closeMenu();
        } else {
            this.openMenu();
        }
    }


    openMenu() {
        if (!this.isOpen) {
            this.isOpen = true;
            disableBodyScroll(this.$content);

            const pageWidth = getPageWidth();
            const pageHeight = getPageHeight();

            addClass(this.$burger, 'is-open');
            addClass(this.$content, 'is-shown');

            this.openTimeline.play(0);

            // TEMP : Preserve multiple bar animations
            anime.timeline({
                easing: 'cubicBezier(.5, .05, .1, .3)',
            })
                .add({
                    targets: this.$burgerBar1,
                    duration: 450,
                    translateX: [0, '7px'],
                    translateY: ['-50%', '-50%'],
                    complete: () => css(this.$burgerBar1, {opacity: 0})
                }, 0)
                .add({
                    targets: this.$burgerBar4,
                    duration: 450,
                    translateX: ['0', '-7px'],
                    translateY: ['-50%', '-50%'],
                    complete: () => css(this.$burgerBar4, {opacity: 0})
                }, 0)
                .add({
                    targets: this.$burgerBar2,
                    duration: 350,
                    translateX: ['0', '3px'],
                    translateY: ['-50%', '-50%'],
                    rotate: '45deg'
                }, 350)
                .add({
                    targets: this.$burgerBar3,
                    duration: 350,
                    translateX: ['0', '-3px'],
                    translateY: ['-50%', '-50%'],
                    rotate: '-45deg'
                }, '-=350');
        }
    }


    closeMenu() {

        if (this.isOpen) {
            this.isOpen = false;
            enableBodyScroll(this.$content);
            css(this.$backgroundCircle, {visibility: 'visible'});

            this.openTimeline.reverse();

            anime.timeline({
                easing: 'cubicBezier(.5, .05, .1, .3)',
            })
                .add({
                    targets: this.$burgerBar2,
                    duration: 350,
                    translateX: 0,
                    rotate: 0,
                    complete: () => css(this.$burgerBar1, {opacity: null})
                }, 0)
                .add({
                    targets: this.$burgerBar3,
                    duration: 350,
                    translateX: 0,
                    rotate: 0,
                    complete: () => css(this.$burgerBar4, {opacity: null})
                }, 0)
                .add({
                    targets: this.$burgerBar1,
                    duration: 350,
                    translateX: ['7px', '0'],
                    translateY: ['-50%', '-50%'],
                }, '350')
                .add({
                    targets: this.$burgerBar4,
                    duration: 350,
                    translateX: ['-7px', 0],
                    translateY: ['-50%', '-50%'],
                }, '350');
        }
    }

    update(data) {

        // Check if triggers
        if (this.$triggers.length > 0) {

            // Get Menu burger properties
            var burgerRect = this.$burger.getBoundingClientRect();

            // Check if Menu is hovering one of this.$triggers
            for (var i = 0, j = this.$triggers.length; i < j; i++) {
                var trigerRect = this.$triggers[i].getBoundingClientRect();
                var toCross_position_top = trigerRect.top;
                var toCross_position_left = trigerRect.left;

                if (burgerRect.top + burgerRect.height < trigerRect.top
                    || burgerRect.top > toCross_position_top + trigerRect.height
                    || burgerRect.left + burgerRect.width < trigerRect.left
                    || burgerRect.left > toCross_position_left + trigerRect.width) {
                    continue;
                } else {
                    var nextClass = this.$triggers[i].getAttribute("data-menu-class");
                    // console.log('NEXT :: ', nextClass);
                    // console.log('FROM :: ', this.$triggers[i]);
                    // Compare to actual class
                    if (this.currentClass != nextClass)
                        this.switchClass(nextClass);
                }
            }
        }

        // Retracted burger
        if (this.mouseOut == true)
            this.retractBurger();

        // Fixed actions
        if (this.$fixedActions && this.$fixedActionsTriggerIn && this.$fixedActionsTriggerOut) {

            var triggerInRect = this.$fixedActionsTriggerIn.getBoundingClientRect();
            var triggerOutRect = this.$fixedActionsTriggerOut.getBoundingClientRect();
            if (this.$fixedActionsTriggerOutOffset)
                var triggerOutOffsetRect = this.$fixedActionsTriggerOutOffset.getBoundingClientRect();

            if (window.scrollY + 180 > triggerInRect.height) {
                // Check color
                // if( window.scrollY < triggerInRect.height + triggerOutOffsetRect.height ) {
                //     this.$fixedActions.classList.add( 'white' );
                // } else {
                //     this.$fixedActions.classList.remove( 'white' );
                // }
                // Use menu class to determine color
                if (this.currentClass === 'white' || this.currentClass === 'default')
                    this.$fixedActions.classList.add('white');
                else
                    this.$fixedActions.classList.remove('white');


                // Check visibility
                //var value = triggerInRect.height + triggerOutRect.height + triggerOutRect.top;
                var value = triggerOutRect.top + triggerOutRect.height;
                //console.log('window scroll : ' + window.scrollY);
                //console.log('value : ' + value);

                if (this.$fixedActionsTriggerOutOffset)
                    value = value + triggerOutOffsetRect.height;
                if (value >= 0) {
                    this.$fixedActions.classList.add('enabled');
                } else {
                    this.$fixedActions.classList.remove('enabled');
                }
            } else
                this.$fixedActions.classList.remove('enabled');
        }

        // Progress Circle Menu
        if (this.$progressCircle) {
            var h = document.documentElement, b = document.body, st = 'scrollTop', sh = 'scrollHeight';
            var percent = (data.last || h[st] || b[st]) / ((h[sh] || b[sh]) - h.clientHeight);
            if (percent < 0.015)
                percent = 0;
            var dashOffset = 230 - (230 * percent);
            css(this.$progressCircle, {strokeDashoffset: dashOffset + 'px'});
        }

    }

    switchClass(nextClass) {
        this.$menu.classList.remove('c-site-menu--color-' + this.currentClass);
        this.$menu.classList.add('c-site-menu--color-' + nextClass);
        this.currentClass = nextClass;
    }
}
