import AbstractPageController from './AbstractPageController';
import { css } from '../utils/CssUtils';
import { addEventListener, removeEventListener } from '../utils/EventUtils';
import { createElementFromHTML, getPageHeight } from '../utils/JsUtils';
import Logger from '../utils/Logger';
import anime from 'animejs';
import { debounce } from 'lodash';


export default class SmoothScrollController extends AbstractPageController {
    constructor( callback = null ) {
        super( callback );
        Logger.log( 'SmoothScrollController->constructor()' );

        this.initDomElements();
        this.addEventListeners();
        this.ready();
    }


    initDomElements() {
        this.$body = document.getElementsByTagName( 'body' )[0];
        this.$smoothScroll = document.querySelector( '.js-smooth-scroll' );
        this.$smoothScrollHeight = createElementFromHTML( '<div></div>' );
        css( this.$smoothScrollHeight, {
            position: 'absolute',
            top: 0,
            left: 0,
            width: '1px',
            visibility: 'hidden'
        } );
        this.$body.appendChild( this.$smoothScrollHeight );
        setInterval( () => {
            css( this.$smoothScrollHeight, { height: this.$smoothScroll.getBoundingClientRect().height + 'px' } );
        }, 16 );
    }


    addEventListeners() {
        requestAnimationFrame( this.scrollEventListener.bind( this ) );
    }


    scrollEventListener() {
        if ( this.scrollAnimation ) {
            // this.scrollAnimation.pause();

            const scrollDistance = window.scrollY || window.pageYOffset;
            console.log( scrollDistance );

            const scrollPercentage = scrollDistance / ( this.$smoothScroll.getBoundingClientRect().height - getPageHeight() );
            console.log( scrollPercentage );

            this.scrollAnimation.seek( scrollPercentage * 1000 );
        }
        else
        {
            this.scrollAnimation = anime( {
                autoplay: false,
                targets: this.$smoothScroll,
                easing: 'linear',
                duration: 1000,
                translateY: [ 0, ( '-' + ( Math.floor( this.$smoothScroll.getBoundingClientRect().height - getPageHeight() ) ) ) + 'px' ],
                elasticity: 1000,
                // opacity: [0, 1]
            } );
        }

        requestAnimationFrame( this.scrollEventListener.bind( this ) );
    }


    destroy() {
        Logger.log( 'SmoothScrollController->destroy()' );
    }
}