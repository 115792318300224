import AbstractComponent from "../AbstractComponent";
import Logger from '../../utils/Logger';
import Flickity from 'flickity';
import { addEventListener, removeEventListener, preventDefault } from "../../utils/EventUtils";


export default class TripleMedias extends AbstractComponent {
    constructor( ...args ) {
        Logger.log( 'TripleMedias->constructor()' );
        super( ...args );

        this.initDomElements();
        this.initSlider();
        this.addEventListeners();
    }


    initDomElements() {
        this.$slider =  this.$el.querySelector( '.js-triple-medias-list' );
    }


    initSlider() {
        this.slider = new Flickity( this.$slider, {
            cellAlign: 'center',
            groupCells: false,
            watchCSS: true,
            wrapAround: false,
            prevNextButtons: false,
            pageDots: false
        } );
    }


    addEventListeners() {
    }


    destroy() {
        Logger.log( 'TripleMedias->destroy()' );

        this.removeEventListeners();
    }


    removeEventListeners() {
    }
}
