import AbstractComponent from "./AbstractComponent";
import { css, addClass, removeClass } from "../utils/CssUtils";
import { getPageHeight, getPageOrientation } from "../utils/JsUtils";
import Logger from "../utils/Logger";
import { debounce } from "lodash";
import { addEventListener } from "../utils/EventUtils";
import anime from "animejs";

export default class Header extends AbstractComponent {
  constructor(...args) {
    Logger.log("Header->constructor()");
    super(...args);
    this.classname = "c-header";
    this.backgroundOffsets = {
      mobile: {
        home: 0,
        page: 175,
      },
      desktop: {
        home: 297,
        page: 250,
      },
    };
    this.buttonScrollOffsets = {
      mobile: 35,
      desktop: 100,
    };
    this.currentOrientation = null;

    this.initDomElements();
    this.updateHeight();
    this.updateBackgroundVideoClass();
    this.addEventListeners();
  }

  initDomElements() {
    this.$button = this.$el.querySelector(".js-" + this.classname + "__button");
    this.$fold = this.$el.querySelector(".js-" + this.classname + "__fold");
    this.$backgroundMedia = this.$el.querySelector(
      ".js-" + this.classname + "__background-media"
    );
    this.$backgroundVideo = this.$backgroundMedia
      ? this.$el.querySelector(".js-" + this.classname + "__background-video")
      : null;
    this.$backgroundVideoMobile = this.$backgroundMedia
      ? this.$el.querySelector(
          ".js-" + this.classname + "__background-video-mobile"
        )
      : null;
    this.$pageIntroduction = this.$el.parentNode.querySelector(
      ".js-c-page-introduction"
    );
  }

  addEventListeners() {
    this.debouncedResizeListener = debounce(
      this.resizeEventListener.bind(this),
      16
    );
    addEventListener(window, "resize", this.debouncedResizeListener);

    if (this.$button) {
      this.boundScrollDownClickListener =
        this.scrollDownClickListener.bind(this);
      addEventListener(
        this.$button,
        "click",
        this.boundScrollDownClickListener
      );
    }
  }

  resizeEventListener() {
    this.updateHeight();
    this.updateBackgroundVideoClass();
  }

  scrollDownClickListener() {
    let scrollTop = getPageHeight();
    scrollTop -=
      this.buttonScrollOffsets[
        this.app.isBreakpointUp("lg") ? "desktop" : "mobile"
      ];
    anime({
      targets:
        window.document.scrollingElement ||
        window.document.body ||
        window.document.documentElement,
      easing: "easeInOutQuad",
      duration: 350,
      scrollTop: scrollTop,
    });
  }

  getType() {
    return this.$el.getAttribute("data-type");
  }

  isType(type) {
    return type == this.getType();
  }

  updateHeight() {
    if (this.app.isMobile && this.currentOrientation == getPageOrientation()) {
      return;
    }

    const pageHeight = getPageHeight();
    let height = pageHeight;
    const offset =
      this.backgroundOffsets[
        this.app.isBreakpointUp("lg") ? "desktop" : "mobile"
      ][this.getType()];
    height += offset;

    if (
      this.isType("page") &&
      this.$pageIntroduction &&
      this.app.isBreakpointUp("lg")
    ) {
      const pageIntroductionHeight =
        this.$pageIntroduction.getBoundingClientRect().height;
      const pageIntroductionSpecificOffset = 100;
      height += pageIntroductionHeight - pageIntroductionSpecificOffset;

      let marginBottom = -1 * (offset + pageIntroductionHeight);

      css(this.$el, { marginBottom: Math.round(marginBottom) + "px" });
    } else {
      if (this.app.isBreakpointDown("md")) {
        css(this.$el, { marginBottom: "0px" });
      }
    }

    // Fix single project height too big on IPhones
    if (
      this.app.isMobile &&
      this.app.isSafari &&
      this.$el.classList.contains("c-header--project")
    ) {
      height = pageHeight + 40;
    }

    css(this.$el, { height: Math.round(height) + "px" });
    css(this.$fold, { height: pageHeight + "px" });
    this.currentOrientation = getPageOrientation();
  }


  updateBackgroundVideoClass() {
    if (this.$backgroundMedia && this.$backgroundVideo) {
      const backgroundMediaBoundingClientRect =
        this.$backgroundMedia.getBoundingClientRect();
      const backgroundMediaWidth = backgroundMediaBoundingClientRect.width;
      const backgroundMediaHeight = backgroundMediaBoundingClientRect.height;
      const backgroundVideoRatio = parseFloat(
        this.$backgroundVideo.getAttribute("data-ratio")
      );
      if (backgroundVideoRatio) {
        if (
          backgroundMediaWidth / backgroundMediaHeight >
          backgroundVideoRatio
        ) {
          removeClass(
            this.$backgroundVideo,
            this.classname + "__background-video--height"
          );
          addClass(
            this.$backgroundVideo,
            this.classname + "__background-video--width"
          );
        } else {
          removeClass(
            this.$backgroundVideoMobile,
            this.classname + "__background-video--height"
          );
          addClass(
            this.$backgroundVideoMobile,
            this.classname + "__background-video--width"
          );
        }
      }
    }
  }

  destroy() {
    Logger.log("Header->destroy()");

    this.removeEventListeners();
  }

  removeEventListeners() {
    removeEventListener(window, "resize", this.debouncedResizeListener);

    if (this.$button) {
      removeEventListener(
        this.$button,
        "click",
        this.boundScrollDownClickListener
      );
    }
  }
}
