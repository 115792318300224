import AbstractPageController from './AbstractPageController';
import Logger from '../utils/Logger';
import { getTimeline, isElementInViewport } from "../utils/AnimationUtils";
// import { TweenMax, TimelineLite, Power2, Linear, Elastic, CSSPlugin } from "gsap/all";

import { addEventListener } from '../utils/EventUtils';
import { debounce } from 'lodash';
import { addClass, css, removeClass } from '../utils/CssUtils';


export default class HomeController extends AbstractPageController {
    constructor( ...args ) {
        super( ...args );

        Logger.log( 'HomeController->constructor()' );


        this.initDomElements();
        this.addEventListeners();
        this.updateVisionKnownBlackBackground();

        // Animations
        this.timelines = [];
        if( this.app.isDesktop ) {
            this.createScene();
        }

        this.updateProjectsBackgroundVideoClass();
        this.ready();
    }


    initDomElements() {
        this.$visionKnowHow = document.querySelector( '.js-vision-know-how' );
        this.$vision = this.$visionKnowHow.querySelector( '.js-vision-know-how__vision' );
        this.$visionIllustration = this.$vision.querySelector( '.js-c-company-highlight__illustration' );
        this.$visionButton = this.$vision.querySelector( '.js-c-company-highlight__button' );
        this.$knowHow = this.$visionKnowHow.querySelector( '.js-vision-know-how__know-how' );
        this.$knowHowIllustration = this.$knowHow.querySelector( '.js-c-company-highlight__illustration' );
        this.$knowHowButton = this.$knowHow.querySelector( '.js-c-company-highlight__button' );
        this.$visionKnowHowBlackBackground = this.$visionKnowHow.querySelector( '.js-vision-know-how__black-background' );
        this.$projectsBackgroundMediaContainer = document.querySelector( '.js-projects__background-media-container' );
        if( this.$projectsBackgroundMediaContainer )
            this.$projectsBackgroundVideo = this.$projectsBackgroundMediaContainer.querySelector( '.js-projects__background-video' );
    }


    addEventListeners() {
        this.debouncedResizeListener = debounce( this.resizeEventListener.bind( this ), 16 );
        addEventListener( window, 'resize', this.debouncedResizeListener );
    }


    resizeEventListener() {
        this.updateVisionKnownBlackBackground();
        this.updateProjectsBackgroundVideoClass();
    }


    updateVisionKnownBlackBackground() {
        const props = {};

        // Top
        props.top = 0;
        props.top += this.$visionIllustration.offsetTop;
        props.top += this.$visionButton.getBoundingClientRect().height;
        props.top = Math.round( props.top );

        // Height
        props.height = 0;
        props.height += this.$knowHow.offsetTop;
        props.height += this.$knowHow.getBoundingClientRect().height;
        props.height -= props.top;
        props.height -= this.$knowHowButton.getBoundingClientRect().height;
        props.height = Math.round( props.height );

        // Pixels units
        props.top += 'px';
        props.height += 'px';

        css( this.$visionKnowHowBlackBackground, props );
    }


    createScene() {

        this.$animatedItems = document.querySelectorAll( '[data-animation]' );
        for( let i = 0, j = this.$animatedItems.length; i < j; i++ )
        {
            let el = this.$animatedItems[i];
            let animationName = el.getAttribute( 'data-animation' );
            let tween = getTimeline( animationName, el );
            this.timelines.push( {
                'tween': tween,
                'el': el
            });

        }
    }


    /**
     * Update call to render animations
     */
    update() {
        if( this.timelines.length > 0 ) {

            // let windowBottom = window.scrollY + window.innerHeight;

            for (let i = 0, j = this.timelines.length; i < j; i++) {
                const item = this.timelines[i].el;
                item.rect = item.getBoundingClientRect();
                // Check if element is not in viewport to skip process : disable cause it's bugged
                // if( !isElementInViewport( item ) ) continue;

                // Special for header
                if( item.getAttribute('data-animation') == 'header' ) {
                    var percent = 1 - ( window.scrollY / window.innerHeight );
                } else {
                    var percent = ( item.rect.top + item.rect.height ) / ( window.innerHeight + item.rect.height );
                }

                let progress = 1 - percent;
                if (progress < 0) continue;
                else if (progress > 1) continue;


                // Update tween
                let tween = this.timelines[i].tween;
                tween.progress(progress);
            }
        }
    }

    updateProjectsBackgroundVideoClass() {
        if ( this.$projectsBackgroundMediaContainer && this.$projectsBackgroundVideo ) {
            const projectsBackgroundMediaContainerBoundingClientRect = this.$projectsBackgroundMediaContainer.getBoundingClientRect();
            const projectsBackgroundMediaContainerWidth = projectsBackgroundMediaContainerBoundingClientRect.width;
            const projectsBackgroundMediaContainerHeight = projectsBackgroundMediaContainerBoundingClientRect.height;
            const projectsBackgroundVideoRatio = parseFloat( this.$projectsBackgroundVideo.getAttribute( 'data-ratio' ) );
            if ( projectsBackgroundVideoRatio ) {
                if ( ( projectsBackgroundMediaContainerWidth / projectsBackgroundMediaContainerHeight ) > projectsBackgroundVideoRatio ) {
                    removeClass( this.$projectsBackgroundVideo, 'projects__background-video--height' );
                    addClass( this.$projectsBackgroundVideo, 'projects__background-video--width' );
                } else {
                    removeClass( this.$projectsBackgroundVideo, 'projects__background-video--width' );
                    addClass( this.$projectsBackgroundVideo, 'projects__background-video--height' );
                }
            }
        }
    }


    destroy() {
        Logger.log( 'HomeController->destroy()' );

        this.removeEventListeners();
    }


    removeEventListeners() {
        removeEventListener( window, 'resize', this.debouncedResizeListener );
    }
}
