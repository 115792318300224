import AbstractPageController from './AbstractPageController';
import Logger from '../utils/Logger';
import {getTimeline} from "../utils/AnimationUtils";


export default class ProjectsController extends AbstractPageController {
    constructor( callback = null ) {
        super( callback );
        Logger.log( 'ProjectsController->constructor()' );

        // Animations
        this.timelines = [];
        if( this.app.isDesktop ) {
            this.createScene();
        }

        this.initFilters();

        this.ready();
    }

    createScene() {

        this.$animatedItems = document.querySelectorAll( '[data-animation]' );
        for( let i = 0, j = this.$animatedItems.length; i < j; i++ )
        {
            let el = this.$animatedItems[i];
            let animationName = el.getAttribute( 'data-animation' );
            let tween = getTimeline( animationName, el );
            this.timelines.push( {
                'tween': tween,
                'el': el
            });
        }
    }

    initFilters() {

        this._filters = {
            'project_type': [],
            'project_sector': []
        };

        this.filtersIds = [  ];
        this.$filters_container = document.querySelector( '.js-filters-projects' );
        this.$filters_heads =  this.$filters_container.querySelectorAll( '.js-filter-head' );
        this.$current_head = this.$filters_heads[0];
        this.$filters_contents =  this.$filters_container.querySelectorAll( '.js-filter-content' );
        this.$current_group = this.$filters_contents[0];
        this.$filters_items =  this.$filters_container.querySelectorAll( '.js-filter-item' );
        this.$current_filter = this.$filters_items[0];
        this.$projects = document.querySelectorAll( '.js-list-project-item' );

        // Bind tabs head
        for( var i = 0, j = this.$filters_heads.length; i < j; i++ ) {
            this.$filters_heads[i].addEventListener( 'click', (e) => {
                this.toggleTabs( e.currentTarget );
            });
        }

        // Bind filter items
        for( var i = 0, j = this.$filters_items.length; i < j; i++ ) {
            this.$filters_items[i].addEventListener( 'click', (e) => {
                this.sortProjects( e.currentTarget );
            });
        }

        // Retrieve default values
        this.getActiveFilters();

    }

    getActiveFilters() {
        for( var i = 0, j = this.$filters_items.length; i < j; i++ )
        {
            if( this.$filters_items[i].classList.contains( 'active' ) ) {
                var group = this.$filters_items[i].getAttribute( 'data-group-parent' );
                var value = this.$filters_items[i].getAttribute( 'data-value' );
                if( value )
                    this._filters[group].push( value );
                else
                    this._filters[group] = [];
            }
        }
    }

    toggleTabs( el ) {

        // Same filter click
        if( this.$current_head == el )
            return;

        // Disable old tab
        if( this.$current_head ) {
            this.$current_head.classList.remove('active');
            // Disable associated content
            this.$current_group.classList.remove( 'active' );
        }

        // Enable new content
        el.classList.add('active');
        var newGroupName = el.getAttribute( 'data-value' );
        var newContentGroup = document.querySelector( '[data-group="' + newGroupName + '"]' );
        newContentGroup.classList.add( 'active' );

        this.$current_head = el;
        this.$current_group = newContentGroup;
    }

    sortProjects( el ) {

        // Get next group and value
        var group = el.getAttribute( 'data-group-parent' );
        var value = el.getAttribute( 'data-value' );

        // If 'all' value
        if( value ) {
            // console.log(value);
            // console.log(this._filters[group]);

            // Same filter click
            if( this._filters[group].indexOf( value ) != -1 ) {
                // console.log('SAME FILTER CLICK BRO');
                return;
            }

            // Switch active class and value
            var currentActive = el.parentNode.querySelector( '.active' );
            currentActive.classList.remove( 'active' );
            this._filters[group] = [];
            el.classList.add( 'active' );
            this._filters[group].push( value );

        } else {

            // Switch active class and value
            var currentActive = el.parentNode.querySelector( '.active' );
            currentActive.classList.remove( 'active' );
            this._filters[group] = [];
            el.classList.add( 'active' );

        }

        // Loop through projects
        var newResultNumber = 0;
        for( var i = 0, j = this.$projects.length; i < j; i++ ) {

            var isValid = false;
            var projectTerms = this.$projects[i].getAttribute( 'data-terms' ).split('|');

            // Check if filtered by project_type
            if( this._filters['project_type'].length > 0 ) {
                // Loop through current filters
                for( var k = 0, l = this._filters['project_type'].length; k < l; k++ ) {
                    // Loop through project terms to compare
                    for( var m = 0, n = projectTerms.length; m < n; m++ ) {
                        if( this._filters['project_type'][k] == projectTerms[m] ) {
                            isValid = true;
                            continue;
                        }
                    }
                }
            }

            // Check if filtered by project_sector
            if( this._filters['project_sector'].length > 0 ) {
                // Loop through current filters
                for( var k = 0, l = this._filters['project_sector'].length; k < l; k++ ) {
                    // Loop through project terms to compare
                    for( var m = 0, n = projectTerms.length; m < n; m++ ) {
                        if( this._filters['project_sector'][k] == projectTerms[m] ) {
                            isValid = true;
                            continue;
                        }
                    }
                }
            }

            if( this._filters['project_type'].length == 0 && this._filters['project_sector'].length == 0 )
                isValid = true;

            // console.log('TEST ::: ', ( newResultNumber % 2 != 0 ));

            // Define new orientation depending of odd / even
            if( newResultNumber % 2 != 0 ) {
                this.$projects[i].classList.add( 'c-list-projects__container__item--reversed' );
            } else {
                // console.log('REMOVE CLASS REVERSED...');
                this.$projects[i].classList.remove( 'c-list-projects__container__item--reversed' );
            }

            // console.log('RESULT NUMBER :: ');
            // console.log(newResultNumber);

            // Manage active class if project is valid
            if( isValid == true ) {
                newResultNumber = newResultNumber + 1;
                this.$projects[i].classList.remove( 'hide' );
            } else
                this.$projects[i].classList.add( 'hide' );
        }

        // Update size of SmoothScroll
        this.app.smoothScrollController.resize();
    }


    /**
     * Update call to render animations
     */
    update() {
        if( this.timelines.length > 0 ) {

            for (let i = 0, j = this.timelines.length; i < j; i++) {
                const item = this.timelines[i].el;
                item.rect = item.getBoundingClientRect();

                // Get percent

                // Special for header
                if( item.getAttribute('data-animation') == 'header' ) {
                    var percent = 1 - ( window.scrollY / window.innerHeight );
                } else {
                    var percent = ( item.rect.top + item.rect.height ) / ( window.innerHeight + item.rect.height );
                }

                let progress = 1 - percent;
                if (progress < 0) continue;
                else if (progress > 1) continue;


                // Update tween
                let tween = this.timelines[i].tween;
                tween.progress(progress);
            }
        }
    }



    destroy() {
        Logger.log( 'ProjectsController->destroy()' );
    }
}