import AbstractPageController from './AbstractPageController';
import Logger from '../utils/Logger';
import { addEventListener } from '../utils/EventUtils';
import { debounce } from 'lodash';
import { addClass, removeClass } from '../utils/CssUtils';
import { getPageWidth, getPageHeight } from '../utils/JsUtils';


export default class NotFound404Controller extends AbstractPageController {
    constructor( ...args ) {
        super( ...args );

        Logger.log( 'NotFound404Controller->constructor()' );


        this.initDomElements();
        this.addEventListeners();
        this.updateBackgroundVideoClass();
        this.ready();
    }


    initDomElements() {
        this.$backgroundMedia = document.querySelector( '.js-not-found-404__background-media' );
        this.$backgroundVideo = this.$backgroundMedia.querySelector( '.js-not-found-404__background-video' );
    }


    addEventListeners() {
        this.debouncedResizeListener = debounce( this.resizeEventListener.bind( this ), 16 );
        addEventListener( window, 'resize', this.debouncedResizeListener );
    }


    resizeEventListener() {
        this.updateBackgroundVideoClass();
    }


    updateBackgroundVideoClass() {
        if ( this.$backgroundMedia && this.$backgroundVideo ) {
            const backgroundMediaWidth = getPageWidth();
            const backgroundMediaHeight = getPageHeight();
            const backgroundVideoRatio = parseFloat( this.$backgroundVideo.getAttribute( 'data-ratio' ) );
            if ( backgroundVideoRatio ) {
                if ( ( backgroundMediaWidth / backgroundMediaHeight ) > backgroundVideoRatio ) {
                    removeClass( this.$backgroundVideo, 'not-found-404__background-video--height' );
                    addClass( this.$backgroundVideo, 'not-found-404__background-video--width' );
                } else {
                    removeClass( this.$backgroundVideo, 'not-found-404__background-video--width' );
                    addClass( this.$backgroundVideo, 'not-found-404__background-video--height' );
                }
            }
        }
    }


    destroy() {
        Logger.log( 'NotFound404Controller->destroy()' );

        this.removeEventListeners();
    }


    removeEventListeners() {
        removeEventListener( window, 'resize', this.debouncedResizeListener );
    }
}
