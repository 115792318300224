import "../utils/RequestAnimationFrame"; // Cross browser RequestAnimationFrame implementation
import "../utils/HtmlMediaElementPlaying"; // HTMLMediaElement playing property support
import { debounce, upperFirst } from "lodash";
import { addClass, css, hasClass, removeClass } from "../utils/CssUtils";
import { addEventListener } from "../utils/EventUtils";
import MobileDetect from "mobile-detect";
import "core-js/features/symbol"; // Required dependency for Barba to work on IE
import "core-js/features/map";
import "core-js/features/object";
import "core-js/features/promise";
import barba from "@barba/core";
import anime from "animejs";
import {
  TweenMax,
  TimelineLite,
  Sine,
  Linear,
  Power1,
  Slow,
  Circ,
  CSSPlugin,
} from "gsap/all";
import DefaultController from "./DefaultController";
import Logger from "../utils/Logger";
import "svgxuse";
import SiteMenu from "../components/SiteMenu";
import Cursor from "../components/Cursor";
import SmoothScrollController from "./SmoothScrollController";
import components from "../components/_map";
import controllers from "./_map";
import { getPageWidth } from "../utils/JsUtils";
import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock";

export default class AppController {
  constructor() {
    Logger.log("AppController->constructor()");

    // Handling singleton creation
    if (!AppController.singleton) {
      // Storing the singleton instance
      AppController.singleton = this;

      // Fields
      this.breakpoints = {
        xs: 0,
        sm: 576,
        md: 768,
        lg: 1024,
        xl: 1280,
        xxl: 1600,
      };
      this.startTime = new Date().getTime();
      this.isLoaderHidden = false;
      this.loaderStartTime = new Date().getTime();
      this.controllers = controllers;
      this.currentController = null;
      this.previousController = null;
      this.components = components;
      this.animation = {
        loader: {
          easing: "easeInOutQuad",
          duration: 500,
          interval: 250,
          timeout: 1500,
        },
      };

      // Initializing
      this.initDomElements();
      disableBodyScroll(this.$loader);
      this.initViewportVhVar();
      this.initDeviceOrientationDetection();
      this.initMenu();
      this.initLoaderTimelines();
      this.initCursor();
      this.initBarba();
      this.instantiateController(
        this.$body.getAttribute("data-controller"),
        this.controllerReady.bind(this)
      );
      this.instantiateComponents();
      this.initSmoothScroll();
      this.checkLoader();
    }

    // Returning the singleton
    return AppController.singleton;
  }

  static getInstance() {
    return AppController.singleton;
  }

  initDomElements() {
    this.$html = document.getElementsByTagName("html")[0];
    this.$body = document.getElementsByTagName("body")[0];
    this.$loader = document.querySelector(".js-c-site-loader");
  }

  initViewportVhVar() {
    this.updateViewportVhVar();
    addEventListener(window, "resize", debounce(this.updateViewportVhVar, 16));
  }

  updateViewportVhVar() {
    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty("--vh", `${vh}px`);
  }

  initDeviceOrientationDetection() {
    // Device and orientation detection fields
    this.device = "mobile";
    this.isMobile = true;
    this.isTablet = false;
    this.isDesktop = false;
    this.orientation = "landscape";
    this.isLandscape = true;
    this.isPortrait = false;

    this.detectDeviceAndOrientation();
    addEventListener(
      window,
      "resize",
      debounce(this.detectDeviceAndOrientation.bind(this), 200)
    );
  }

  detectDeviceAndOrientation() {
    this.detectDevice();
    this.detectOrientation();
  }

  detectDevice() {
    removeClass(this.$html, "mobile");
    removeClass(this.$html, "tablet");
    removeClass(this.$html, "desktop");
    const md = new MobileDetect(window.navigator.userAgent);
    //console.log(md.ua);

    this.device = md.tablet() ? "tablet" : md.mobile() ? "mobile" : "desktop";
    this.isMobile = this.device == "mobile";
    this.isTablet = this.device == "tablet";
    this.isDesktop = this.device == "desktop";
    // this.isSafari = ( md.match( 'Safari' ) && !md.match( 'Chrome' ) && md.ua.indexOf( 'CriOS' ) == -1 ); // to avoid chrome IOS to be safari detected
    this.isSafari = md.match("Safari") && !md.match("Chrome");
    this.isEdge = md.match("Edge") && md.match("Safari") && md.match("Chrome");
    this.isIE = md.match("MSIE") || md.match("Trident");
    addClass(this.$html, this.device);
    if (this.isEdge) addClass(this.$html, "edge");
  }

  detectOrientation() {
    removeClass(this.$html, "landscape");
    removeClass(this.$html, "portrait");
    this.orientation =
      window.innerWidth > window.innerHeight ? "landscape" : "portrait";
    this.isLandscape = this.orientation == "landscape";
    this.isPortrait = this.orientation == "portrait";
    addClass(this.$html, this.orientation);
  }

  getBreakpointNext(breakpoint) {
    let breakpointFound = false;
    for (let currentBreakpoint in this.breakpoints) {
      if (breakpointFound) {
        return currentBreakpoint;
      }
      if (currentBreakpoint == breakpoint) {
        breakpointFound = true;
      }
    }
    return null;
  }

  getBreakpointMin(breakpoint) {
    if (Object.prototype.hasOwnProperty.call(this.breakpoints, breakpoint)) {
      return this.breakpoints[breakpoint];
    }
    return null;
  }

  getBreakpointMax(breakpoint) {
    const nextBreakpoint = this.getBreakpointNext(breakpoint);
    if (nextBreakpoint != null) {
      if (
        Object.prototype.hasOwnProperty.call(this.breakpoints, nextBreakpoint)
      ) {
        return this.breakpoints[nextBreakpoint] - 0.02;
      }
    } else if (
      Object.prototype.hasOwnProperty.call(this.breakpoints, breakpoint)
    ) {
      return Infinity;
    }
    return null;
  }

  isBreakpointUp(breakpoint) {
    const min = this.getBreakpointMin(breakpoint);
    if (min != null) {
      return getPageWidth() >= min;
    }
    return false;
  }

  isBreakpointDown(breakpoint) {
    const max = this.getBreakpointMax(breakpoint);
    if (max) {
      return getPageWidth() < max;
    }
    return false;
  }

  isBreakpointBetween(lower, upper) {
    const min = this.getBreakpointMin(lower);
    const max = this.getBreakpointMax(upper);
    if (min != null && max != null) {
      const pageWidth = getPageWidth();
      return pageWidth >= min && pageWidth < max;
    } else if (max == null) {
      return this.isBreakpointUp(lower);
    } else if (min == null) {
      return this.isBreakpointDown(upper);
    }
    return false;
  }

  initMenu() {
    this.menu = new SiteMenu();
  }

  initCursor() {
    if (this.isDesktop) this.cursor = new Cursor();
    else {
      // USELESS SINCE NETWORK IS TOO FAST (cut animation)
      // Bind all links to have echo effect on click
      // this.echoLinks = this.$body.querySelectorAll( '.c-button' );
      // this.echoLinksTimelines = [];
      // for ( let i = 0, j = this.echoLinks.length; i < j; i++ ) {
      //     this.echoLinksTimelines[i] = new TimelineLite({ paused: true, delay: 0 });
      //     var echo_outer = this.echoLinks[i].querySelector( '.c-button__circle--outer__echo' );
      //     var echo_inner = this.echoLinks[i].querySelector( '.c-button__circle--inner__echo' );
      //     this.echoLinksTimelines[i]
      //         .set( [ echo_inner, echo_outer ], { autoAlpha: 1 } )
      //         .fromTo( echo_inner, 1.75 ,{ width: '110%', height: '110%' }, { autoAlpha: 0, delay: 0.17, width: '310%', height: '310%', ease: Linear.easeIn }, 'start' )
      //         .fromTo( echo_outer, 1.75 ,{ width: '110%', height: '110%' }, { autoAlpha: 0, width: '310%', height: '310%', ease: Linear.easeIn }, 'start');
      //
      //     this.echoLinks[i].addEventListener( 'touchstart', (e) => {
      //         this.echoLinksTimelines[i].play(0);
      //     } )
      // }
    }
  }

  instantiateController(namespace, callback = null) {
    this.$body.setAttribute("data-controller", namespace);

    if (Object.prototype.hasOwnProperty.call(this.controllers, namespace)) {
      const controller = this.controllers[namespace];

      if (controller) {
        this.setCurrentController(new controller.default(callback));
        return;
      }
    }

    this.setCurrentController(new DefaultController(callback));
  }

  updateBodyClassesFromContainers(currentContainer, nextContainer) {
    if (currentContainer) {
      removeClass(this.$body, currentContainer.getAttribute("data-body-class"));
    }

    if (nextContainer) {
      const nextContainerBodyClass =
        nextContainer.getAttribute("data-body-class");
      if (
        nextContainerBodyClass &&
        !hasClass(this.$body, nextContainerBodyClass)
      ) {
        addClass(this.$body, nextContainerBodyClass);
      }
    }
  }

  setCurrentController(controller) {
    this.previousController = this.currentController;
    this.currentController = controller;
  }

  getCurrentController() {
    return this.currentController;
  }

  getPreviousController() {
    return this.previousController;
  }

  clearPreviousController() {
    this.destroyPreviousController();
    this.resetPreviousController();
  }

  destroyPreviousController() {
    if (this.previousController) {
      this.previousController.destroy();
    }
  }

  resetPreviousController() {
    this.previousController = null;
  }

  instantiateComponents() {
    window.addEventListener("DOMContentLoaded", (event) => {
      const components = this.$body.querySelectorAll("[data-component]");
      for (let i = 0; i < components.length; i++) {
        const component = components[i];
        const name = component.getAttribute("data-component");
        if (Object.prototype.hasOwnProperty.call(this.components, name)) {
          const componentClass = this.components[name];
          if (componentClass) {
            new componentClass.default(component);
          }
        }
      }
    });
  }

  initBarba() {
    // barba.init( {
    //     timeout: 3000,
    //     prevent: function ( data ) {
    //         if ( data.el.href == window.location.href ) {
    //             data.event.preventDefault();
    //             return true;
    //         }
    //     },
    //     transitions: []
    // } );
  }

  initSmoothScroll() {
    if (this.isDesktop) {
      this.smoothScrollController = new SmoothScrollController();

      this.smoothScrollController.on("smooth_update", (data) => {
        this.update(data);
      });
    } else {
      this.initCustomRaf();
    }
  }

  initCustomRaf() {
    this.ticking = false;
    this.lastScrollY = window.scrollY;
    addEventListener(window, "scroll", this.onScrollHandler.bind(this));
  }

  onScrollHandler() {
    this.lastScrollY = window.scrollY;
    this.raf();
  }

  raf() {
    if (!this.ticking) {
      requestAnimationFrame(this.run.bind(this));
      this.ticking = true;
    }
  }

  run() {
    this.update({ last: this.lastScrollY });
    this.ticking = false;
  }

  update(data) {
    this.getCurrentController().update(data);

    this.menu.update(data);
  }

  initLoaderTimelines() {
    this.$loader_circle_background = this.$loader.querySelector(
      ".c-site-loader__background"
    );
    this.$loader_disc = this.$loader.querySelector(".c-site-loader__disc");
    this.$loader_circleInner = this.$loader.querySelector(
      ".c-site-loader__circle--inner"
    );
    this.$loader_circleOuter = this.$loader.querySelector(
      ".c-site-loader__circle--outer"
    );
    this.$loader_echoCircleInner = this.$loader.querySelector(
      ".c-site-loader__circle--inner__echo"
    );
    this.$loader_echoCircleOuter = this.$loader.querySelector(
      ".c-site-loader__circle--outer__echo"
    );
    this.$burger = this.$body.querySelector(".c-site-menu__burger");
    this.burger_rect = this.$burger.getBoundingClientRect();

    this.hideLoaderTl = new TimelineLite({ paused: true });
    this.hideLoaderTl
      .set([this.$loader_echoCircleInner, this.$loader_echoCircleOuter], {
        autoAlpha: 1,
      })
      .fromTo(
        [this.$loader_echoCircleInner, this.$loader_echoCircleOuter],
        0.5,
        { width: "100%", height: "100%" },
        {
          autoAlpha: 0,
          delay: 0.1,
          width: "750%",
          height: "750%",
          ease: Linear.easeOut,
          onStart: () => {
            this.$loader.classList.remove("animate-in");
            this.$loader.classList.add("animate-out");
          },
          onComplete: () => {
            // Hiding the loader
            css(this.$loader, { display: "none", opacity: null });

            // Updating the 'isLoaderHidden' flag
            this.isLoaderHidden = false;

            // Callback the controller before hiding the loader
            if (this.currentController.needsLoader()) {
              this.currentController.afterReveal();
            }
          },
        },
        "start"
      )
      .fromTo(
        [this.$loader_circleOuter, this.$loader_circleInner, this.$loader_disc],
        0.4,
        { scale: 1 },
        {
          scale: 2,
          autoAlpha: 0,
          ease: Power1.easeOut,
          onStart: () => {
            this.$loader_disc.style.animationName = "none";
            this.$loader_circleOuter.style.animationName = "none";
          },
        },
        "start+=0.2"
      )
      .fromTo(
        this.$loader_circle_background,
        1,
        { scale: 50 },
        { scale: 0, ease: Linear.easeNone },
        "start+=0.25"
      )
      .to(
        this.$loader_circle_background,
        0.25,
        {
          autoAlpha: 0,
          ease: Linear.easeNone,
          onStart: () => {
            this.menu.$burger.classList.remove("hidden");
          },
        },
        "start+=1.25"
      );
  }

  showLoader(animation) {
    if (this.isLoaderHidden) {
      if (!animation) {
        animation = this.animation.loader;
      }

      css(this.$loader, { display: "block", opacity: 0 });
      anime({
        targets: this.$loader,
        easing: animation.easing,
        duration: animation.duration,
        opacity: [0, 1],
        complete: () => {
          // Updating the 'isLoaderHidden' flag
          this.isLoaderHidden = false;
        },
      });
    }
  }

  hideLoader(animation) {
    if (!this.isLoaderHidden) {
      if (!animation) {
        animation = this.animation.loader;
      }

      // Callback the controller before hiding the loader
      if (this.currentController.needsLoader()) {
        this.currentController.beforeReveal();
      }

      enableBodyScroll(this.$loader);

      this.hideLoaderTl.play();
    }
  }

  checkLoader() {
    if (!this.currentController.needsLoader()) {
      css(this.$loader, { display: "none" });
    }
  }

  controllerReady() {
    if (this.currentController.needsLoader()) {
      // Change loader timing if Safari because of svg rendering bad on init (drawed) cf loader safari iphone
      setTimeout(
        () => {
          this.$loader.classList.add("animate-in");
        },
        this.isSafari == true ? 250 : 0
      );
      var currentTime = new Date().getTime();
      var timeDifference = currentTime - this.loaderStartTime;
      var loaderTimeout = this.animation.loader.timeout - timeDifference;
      setTimeout(
        () => {
          this.hideLoader();
        },
        loaderTimeout > 0 ? loaderTimeout : 0
      );
    }
  }
}
