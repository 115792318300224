import { TweenMax, TimelineLite, Quart, Linear, Power0, Power1, Power2, Power3, Cubic, CSSPlugin } from "gsap/all";

/**
 * Detect if element is visible in viewport
 * @param {Element} `el` Element to check if it's visible in viewport.
 */
export function isElementInViewport( el ) {
    var rect = el.getBoundingClientRect();

    return ( rect.top + rect.height <= 0 && rect.top > (window.innerHeight || document.documentElement.clientHeight) &&
        rect.bottom <= (window.innerHeight || document.documentElement.clientHeight)
    );
}


/**
 * Get Timeline for SCROLL animation
 * @param {String} `animationName` animation timeline to set
 * @param {Element} `el` element to animate
 */
export function getTimeline( animationName, el ) {

    var tween = new TimelineLite({paused: true});

    switch (animationName) {

        // HEADER
        case 'header':
            // var content = el.querySelector( '.c-header__fold' );
            var yellowBar = el.querySelector( '.c-header__title__bar' ) || el.querySelector( '.c-header__tagline__bar' );
            var logo = el.querySelector( '.c-header__logo' );
            if( content )
                tween.fromTo( content, 1, { y: 0 }, { y: -100, ease: Linear.easeNone }, 'start' );
            if( yellowBar )
                tween.fromTo( yellowBar, 1, { y: 0 }, { y: -170, ease: Linear.easeNone }, 'start' );
            if( logo )
                tween.fromTo( logo, 1, { y: 0 }, { y: -50, ease: Linear.easeNone }, 'start+=0.1' );
            break;

        // GENERIC PARALLAX
        case 'parallaxOne':
            tween.fromTo( el, 1, { y: 75 }, { y: -35, ease: Linear.easeNone } );
            break;
        case 'parallaxTwo':
            tween.fromTo( el, 1, { y: 100 }, { y: -30, ease: Linear.easeNone } );
            break;
        case 'parallaxThree':
            tween.fromTo( el, 1, { y: 100 }, { y: -50, ease: Linear.easeNone } );
            break;


        // INTRODUCTION
        case 'introductionTitle':
            tween.fromTo( el, 1, { y: 55 }, { y: -35, ease: Linear.easeNone }, 'start' );
            break;
        case 'introductionDescription':
            tween.fromTo( el, 1, { y: 75 }, { y: -35, ease: Linear.easeNone }, 'start' );
            break;


        // VISION HIGHLIGHT
        case 'visionHighlightTitle':
            tween.fromTo( el, 1, { y: 120 }, { y: -55, ease: Linear.easeNone }, 'start' );
            break;
        case 'visionHighlightYellowBar':
            tween.fromTo( el, 1, { y: 150 }, { y: -150, ease: Linear.easeNone }, 'start' );
            break;
        case 'visionHighlightDescription':
            tween.fromTo( el, 1, { y: 75 }, { y: -55, ease: Linear.easeNone }, 'start' );
            break;
        case 'visionHighlightIllustration':
            tween.fromTo( el, 1, { y: 50 }, { y: -100, ease: Linear.easeNone }, 'start' );
            break;


        // VISION QUOTE
        case 'visionQuoteYellowBar':
            tween.fromTo( el, 1, { width: '50%' }, { width: '40%', ease: Linear.easeNone }, 'start' ).to( el, 1, { width: '30%', y: -50, ease: Linear.easeNone } );
            break;

        case 'visionQuoteTitle':
            tween.fromTo( el, 1, { y: 120 }, { y: -55, ease: Linear.easeNone }, 'start' );
            break;

        case 'visionQuoteDescription':
            tween.fromTo( el, 1, { y: 75 }, { y: -55, ease: Linear.easeNone }, 'start' );
            break;

        case 'visionQuoteButton':
            tween.fromTo( el, 1, { y: 40 }, { y: -55, ease: Linear.easeNone }, 'start' );
            break;


        // COMPANY HIGHLIGHT
        case 'companyHighlightText':
            var title = el.querySelector( '.c-company-highlight__title' );
            var description = el.querySelector( '.c-company-highlight__description' );
            if( title )
                tween.fromTo( title, 1, { y: 75 }, { y: -55, ease: Linear.easeNone }, 'start' );
            if( description )
                tween.fromTo( description, 1, { y: 45 }, { y: -55, delay: 0.2, ease: Linear.easeNone }, 'start' );
            break;
        case 'companyHighlightIllustration':
            var yellowBar = el.querySelector( '.c-company-highlight__bar' );
            var ribbon = el.querySelector( '.c-company-highlight__ribbon' );
            tween.fromTo( yellowBar, 1, { y: 50 }, { y: -200, ease: Linear.easeNone }, 'start' )
                .fromTo( ribbon, 0.27, { width: '100%' }, { width: '50%', ease: Linear.easeNone }, 'start' );
            break;


        // KNOW HOW BLACK BACKGROUND
        // case 'knowHowBlackRibbon':
            // tween.fromTo( el, 1, { width: '100%' }, { width: '30%', ease: Linear.easeNone }, 'start' )
            // break;

        // KNOW HOW BLACK CONTENT
        case 'knowHowBackHighlightContent':
            tween.fromTo( el, 0.75, { y: 120 }, { y: -35, ease: Linear.easeNone }, 'start' );
            break;


        // KNOW HOW EXPERTISE BOX
        case 'knowHowBlackExpertiseBoxMedia':
            tween.fromTo( el, 1, { y: 50 }, { y: -100, ease: Linear.easeNone }, 'start' );
            break;
        case 'knowHowBlackExpertiseBoxYellowBar':
            tween.fromTo( el, 1, { y: 100 }, { y: -100, ease: Linear.easeNone }, 'start' );
            break;

        // KNOW HOW JOBS
        case 'knowHowJobsBlock':
            var content = el.querySelector( '.c-know-how-highlight' );
            var picture_left = el.querySelector( '.c-know-how-cutout-image__left' );
            var picture_right = el.querySelector( '.c-know-how-cutout-image__right' );
            tween.fromTo( content, 0.75, { y: 120 }, { y: -35, ease: Linear.easeNone }, 'start' );
            tween.fromTo( picture_left, 1, { y: 50 }, { y: -50, ease: Linear.easeNone }, 'start' );
            tween.fromTo( picture_right, 1, { y: -35 }, { y: 35, ease: Linear.easeNone }, 'start' );
            break;

        // KNOW HOW QUOTE
        case 'knowHowQuoteBlock':
            var content = el.querySelector( '.c-know-how-quote__quote' );
            var yellowBar = el.querySelector( '.c-know-how-quote__bar' );
            var illustration = el.querySelector( '.c-know-how-quote__illustration' );
            tween.fromTo( content, 0.75, { y: 120 }, { y: -35, ease: Linear.easeNone }, 'start' );
            tween.fromTo( illustration, 1, { y: 35 }, { y: -35, ease: Linear.easeNone }, 'start' );
            tween.fromTo( yellowBar, 1, { y: 0 }, { y: -200, ease: Linear.easeNone }, 'start' );
            break;

            tween.fromTo( el, 0.75, { y: 80 }, { y: -35, ease: Linear.easeNone }, 'start' );
            break;
        case 'knowHowQuoteYellowBar':
            break;


        // project-medias-backgrounds
        case 'project-medias-backgrounds':
            var background = el.querySelector( '.c-project-media__container__background' );
            var media = el.querySelector( '.c-project-media__container__media' );
            tween.from( media, 1, { y: 125, ease: Cubic.easeOut }, 'start' );
            if( background && !el.classList.contains('c-project-media--fullscreen')) {
                tween.from(background, 1, {width: '75%', ease: Cubic.easeOut}, 'start');
            }
            break;

        // project-double-medias-backgrounds
        case 'project-double-medias-backgrounds':
            var background = el.querySelector( '.c-project-double-medias__container__background' );
            var mediaLeft = el.querySelector( '.c-project-double-medias__container__media--left' );
            var mediaRight = el.querySelector( '.c-project-double-medias__container__media--right' );
            tween.from( mediaLeft, 1, { y: 125, ease: Cubic.easeOut }, 'start' );
            tween.from( mediaRight, 1, { y: 125, ease: Cubic.easeOut }, 'start' );
            if( background && !el.classList.contains('c-project-double-medias--fullscreen')) {
                tween.from( background, 1, { width: '45%', ease: Cubic.easeOut }, 'start' );
            }
            break;

        // project-triple-medias-backgrounds
        case 'project-triple-medias-backgrounds':
            var background = el.querySelector( '.c-project-triple-medias__container__background' );
            var medias = el.querySelectorAll( '.c-project-triple-medias__container__media' );
            tween.staggerFrom( medias, 1, { y: 75, ease: Cubic.easeOut }, 0.05, 'start' );
            if( background && !el.classList.contains('c-project-triple-medias--fullscreen')) {
                tween.from( background, 1, { width: '65%', ease: Cubic.easeOut }, 'start' );
            }
            break;


        // CROSS NAV
        case 'cross-nav':
            console.log(el);
            var mask = el.querySelector( '.c-project-cross-nav__container__mask' );
            tween.fromTo( mask, 2, { opacity: 1.05 }, { opacity: 0.3, ease: Cubic.easeOut } );
            break;

        // FOOTER
        case 'footer':
            // tween.fromTo( el, 1, { y: 150 }, { y: 0, ease: Cubic.easeOut } );
            break;
    }

    return tween;
}