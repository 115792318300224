import AbstractPageController from './AbstractPageController';
import Logger from '../utils/Logger';
import {getTimeline} from "../utils/AnimationUtils";


export default class DefaultController extends AbstractPageController {
    constructor( callback = null ) {
        super( callback );
        Logger.log( 'DefaultController->constructor()' );

        // Animations
        this.timelines = [];
        if( this.app.isDesktop ) {
            this.createScene();
        }

        this.ready();
    }

    createScene() {

        this.$animatedItems = document.querySelectorAll( '[data-animation]' );
        for( let i = 0, j = this.$animatedItems.length; i < j; i++ )
        {
            let el = this.$animatedItems[i];
            let animationName = el.getAttribute( 'data-animation' );
            let tween = getTimeline( animationName, el );
            this.timelines.push( {
                'tween': tween,
                'el': el
            });
        }
    }

    /**
     * Update call to render animations
     */
    update() {
        if( this.timelines.length > 0 ) {

            for (let i = 0, j = this.timelines.length; i < j; i++) {
                const item = this.timelines[i].el;
                item.rect = item.getBoundingClientRect();

                // Get percent

                // Special for header
                if( item.getAttribute('data-animation') == 'header' ) {
                    var percent = 1 - ( window.scrollY / window.innerHeight );
                } else {
                    var percent = ( item.rect.top + item.rect.height ) / ( window.innerHeight + item.rect.height );
                }

                let progress = 1 - percent;
                if (progress < 0) continue;
                else if (progress > 1) continue;


                // Update tween
                let tween = this.timelines[i].tween;
                tween.progress(progress);
            }
        }

        // Fixed actions
        if( this.$fixedActions && this.$fixedActionsTriggerIn && this.$fixedActionsTriggerOut && this.$fixedActionsTriggerOutOffset ) {

            var triggerInRect = this.$fixedActionsTriggerIn.getBoundingClientRect();
            var triggerOutRect = this.$fixedActionsTriggerOut.getBoundingClientRect();
            var triggerOutOffsetRect = this.$fixedActionsTriggerOutOffset.getBoundingClientRect();

            if( window.scrollY + 180 > triggerInRect.height ) {

                // Check color
                if( window.scrollY < triggerInRect.height + triggerOutOffsetRect.height ) {
                    this.$fixedActions.classList.add( 'white' );
                } else {
                    this.$fixedActions.classList.remove( 'white' );
                }

                // Check visibility
                if( window.scrollY < triggerInRect.height + triggerOutRect.height + triggerOutOffsetRect.height ) {
                    this.$fixedActions.classList.add( 'enabled' );
                } else {
                    this.$fixedActions.classList.remove( 'enabled' );
                }
            }
            else
                this.$fixedActions.classList.remove( 'enabled' );
        }
    }



    destroy() {
        Logger.log( 'DefaultController->destroy()' );
    }
}